import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { isOfficialIdentity } from "../utils/identityUtils";
import OfficialBadge from "./OfficialBadge";
import { Box } from "@mui/material";

type Props = {
  identityName: string;
};

export default function IdentityButton({ identityName }: Props) {
  return (
    <Link to={`/identity/${identityName}`}>
      <Button variant="contained" size="large" sx={{ textTransform: "none" }}>
        @@{identityName}
        {isOfficialIdentity(identityName) && (
          <Box component="span" sx={{ ml: 1 }}>
            <OfficialBadge hideLabel />
          </Box>
        )}
      </Button>
    </Link>
  );
}
