import { Box, Tooltip, Typography } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";

const OfficialBadge = ({ hideLabel }: { hideLabel?: boolean }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        gap: 1,
        px: hideLabel ? 0.5 : 1.5,
        py: 0.5,
        borderRadius: 20,
        background: "linear-gradient(to right, #3b82f6, #2563eb)",
        color: "white",
        boxShadow: 1,
        width: "fit-content",
        marginLeft: "auto",
      }}
    >
      {hideLabel ? (
        <Tooltip title="Official" placement="top">
          <VerifiedIcon sx={{ fontSize: 20 }} />
        </Tooltip>
      ) : (
        <>
          <VerifiedIcon sx={{ fontSize: 20 }} />
          <Typography sx={{ fontSize: "0.875rem", fontWeight: 500 }}>
            Official
          </Typography>
        </>
      )}
    </Box>
  );
};

export default OfficialBadge;
