"use client";
import QueryKey from "../utils/queryKeys";
import { useQuery } from "@tanstack/react-query";
import { gql } from "@urql/core";
import { useUrqlClient } from "./useUrqlClient";

type QueryResponse = {
  shinkaiIdentities: {
    stakedTokens: string;
  }[];
};

const Query = gql<QueryResponse, { owner: string }>`
  query ($owner: String!) {
    shinkaiIdentities(first: 1000, where: { nft_: { owner: $owner } }) {
      stakedTokens
    }
  }
`;

export const useGetProfileData = (owner: string) => {
  const { data: urqlData } = useUrqlClient();
  owner = owner.toLowerCase();

  return useQuery({
    queryKey: [QueryKey.PROFILE_DATA, { urqlData, owner }],
    queryFn: async () => {
      const urqlClient = urqlData?.client;
      if (!urqlClient) return null;
      const result = await urqlClient.query(Query, { owner });
      return {
        stakedTokens: result.data?.shinkaiIdentities.reduce(
          (prev: any, curr: any) => prev + BigInt(curr.stakedTokens),
          0n,
        ),
      };
    },
    staleTime: 60000,
  });
};
