"use client";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ClaimIdentityButton from "./ClaimIdentityButton";
import { useAccount } from "wagmi";
import { formatEther, parseEther } from "viem";
import { useSimulateShinkaiRegistryClaimIdentity } from "../generated";
import ApproveKaiButton from "./ApproveKaiButton";
import { ZERO_ADDRESS, getStakeReq } from "../helper";
import { useDebounce } from "usehooks-ts";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGetIdentityData } from "../hooks/useGetIdentityData";
import { useGetUserKaiTokenBalance } from "../hooks/useGetUserKaiTokenBalance";
import { useQueryClient } from "@tanstack/react-query";
import { useGetUserKaiTokenAllowance } from "../hooks/useGetUserKaiTokenAllowance";
import { useGetShinkaiNamespaces } from "../hooks/useGetShinkaiNamespaces";
import { useGetDefaultShinkaiNamespace } from "../hooks/useGetDefaultShinkaiNamespace";
import { getShinkaiRegistryAddress } from "../contracts";

export default function ClaimIdentityForm() {
  const queryClient = useQueryClient();
  const queryParameters = new URLSearchParams(window.location.search);
  const referrerFromUrl = queryParameters.get("referrer");
  const encryptionPublicKeyFromUrl = queryParameters.get("encryption_pk") ?? "";
  const signaturePublicKeyFromUrl = queryParameters.get("signature_pk") ?? "";
  const nodeAddressFromUrl = queryParameters.get("node_address") ?? "";
  const { address } = useAccount();
  const { data: shinkaiNamespaces } = useGetShinkaiNamespaces();
  const { data: defaultShinkaiNamespace } = useGetDefaultShinkaiNamespace();

  const referrerNamespaceFromUrl =
    referrerFromUrl?.substring(referrerFromUrl.lastIndexOf(".")) ?? "";
  const referrerNameFromUrl = referrerFromUrl?.substring(
    0,
    referrerFromUrl.lastIndexOf("."),
  );

  const [advancedOptionsExpanded, setAdvancedOptionsExpanded] = useState(
    !!referrerFromUrl ||
      !!encryptionPublicKeyFromUrl ||
      !!signaturePublicKeyFromUrl ||
      !!nodeAddressFromUrl,
  );
  const [identityName, setIdentityName] = useState("");
  const [identityNamespace, setIdentityNamespace] = useState(
    defaultShinkaiNamespace,
  );
  const [referrerName, setReferrerName] = useState(referrerNameFromUrl ?? "");

  const [referrerNamespace, setReferrerNamespace] = useState(
    referrerNamespaceFromUrl
      ? referrerNamespaceFromUrl
      : defaultShinkaiNamespace,
  );

  const [stake, setStake] = useState("");
  const [stakeBN, setStakeBN] = useState(0n);
  const [claimIdentityError, setClaimIdentityError] = useState("");
  const [claimIdentitySettled, setClaimIdentitySettled] = useState(false);
  const [encryptionPublicKey, setEncryptionPublicKey] = useState(
    encryptionPublicKeyFromUrl,
  );
  const [signaturePublicKey, setSignaturePublicKey] = useState(
    signaturePublicKeyFromUrl,
  );
  const [nodeAddress, setNodeAddress] = useState(nodeAddressFromUrl);

  const debouncedReferrer = useDebounce(referrerName, 500);
  const { data: referrerIdentityData } = useGetIdentityData(
    debouncedReferrer + referrerNamespace,
  );

  const { data: shinTokenAllowance, queryKey: shinTokenAllowanceQueryKey } =
    useGetUserKaiTokenAllowance(getShinkaiRegistryAddress());

  const { data: shinTokenBalance } = useGetUserKaiTokenBalance();

  const debouncedName = useDebounce(identityName, 500);
  const referrerInvalid = !!debouncedReferrer && !referrerIdentityData;

  useEffect(() => {
    if (!referrerNamespace && referrerNamespaceFromUrl) {
      setReferrerNamespace(referrerNamespaceFromUrl);
    }
  }, [referrerNamespace, referrerNamespaceFromUrl]);

  const {
    refetch: refetchClaimIdentity,
    isSuccess: isSuccessClaimIdentity,
    error: errorClaimIdentity,
    isFetching,
  } = useSimulateShinkaiRegistryClaimIdentity({
    address: getShinkaiRegistryAddress(),
    args: [
      {
        name: debouncedName,
        namespace: shinkaiNamespaces[identityNamespace],
        stakeAmount: 0n,
        owner: ZERO_ADDRESS,
        referrer: !!debouncedReferrer
          ? debouncedReferrer + referrerNamespace
          : "",
      },
    ],
    query: {
      enabled:
        debouncedName.length > 0 &&
        !referrerInvalid &&
        shinkaiNamespaces != null,
    },
  });

  useEffect(() => {
    setIdentityNamespace(defaultShinkaiNamespace);
  }, [defaultShinkaiNamespace]);

  useEffect(() => {
    setClaimIdentitySettled(!!isSuccessClaimIdentity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessClaimIdentity, isFetching]);

  useEffect(() => {
    if (errorClaimIdentity) {
      setClaimIdentityError(errorClaimIdentity.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorClaimIdentity]);

  const stakeReq = getStakeReq(
    identityName,
    identityNamespace,
    !!referrerIdentityData,
  );
  const nameUnavailable =
    claimIdentityError.includes("IdentityNotAvailable") ||
    identityName === "localhost";
  const nameInvalid = new RegExp(/[^a-z0-9_]/).test(identityName);
  const stakeError = stakeBN < stakeReq;
  const balanceError = (shinTokenBalance ?? 0) < stakeBN;

  const errorsInInputs =
    stakeError || balanceError || referrerInvalid || nameInvalid;
  const buttonDisabled = errorsInInputs || !claimIdentitySettled;

  function handleNameInputChanged(newName: string) {
    setClaimIdentityError("");
    setClaimIdentitySettled(false);
    setIdentityName(newName);
    const newStakeReq = getStakeReq(
      newName,
      identityNamespace,
      !!referrerIdentityData,
    );
    handleStakeInputChanged(formatEther(newStakeReq));
  }

  function handleNamespaceInputChanged(newNamespace: string) {
    setClaimIdentityError("");
    setClaimIdentitySettled(false);
    setIdentityNamespace(newNamespace);
    const newStakeReq = getStakeReq(
      identityName,
      newNamespace,
      !!referrerIdentityData,
    );
    handleStakeInputChanged(formatEther(newStakeReq));
  }

  function handleStakeInputChanged(value: string) {
    let newValue = stake;
    try {
      setStakeBN(parseEther(value as any));
      newValue = value;
    } catch (e) {}
    setStake(newValue);
  }

  function handleReferrerNameInputChanged(newReferrerName: string) {
    setClaimIdentityError("");
    setClaimIdentitySettled(false);
    setReferrerName(newReferrerName);
  }

  function handleReferrerNamespaceInputChanged(newReferrerNamespace: string) {
    setClaimIdentityError("");
    setClaimIdentitySettled(false);
    setReferrerNamespace(newReferrerNamespace);
  }

  function handleEncryptionPublicKeyInputChanged(newValue: string) {
    setEncryptionPublicKey(newValue);
  }

  function handleSignaturePublicKeyInputChanged(newValue: string) {
    setSignaturePublicKey(newValue);
  }

  function handleNodeAddressInputChanged(newValue: string) {
    setNodeAddress(newValue);
  }

  return (
    <Stack
      sx={{ gap: 2, width: "100%", alignItems: "center", maxWidth: "576px" }}
    >
      <Stack sx={{ flexDirection: "row", width: "100%" }}>
        <TextField
          color="secondary"
          label="Identity name"
          onChange={(e) => handleNameInputChanged(e.target.value)}
          value={identityName}
          error={nameUnavailable || nameInvalid}
          helperText={
            nameUnavailable
              ? "Identity is unavailable"
              : nameInvalid
              ? "Can only contain lowercase letters, numbers, and underscores."
              : undefined
          }
          sx={{ flexGrow: 1 }}
          InputProps={{ startAdornment: "@@" }}
        />
        <TextField
          select
          disabled={Object.keys(shinkaiNamespaces).length <= 1}
          color="secondary"
          label="Namespace"
          onChange={(e) => handleNamespaceInputChanged(e.target.value)}
          value={identityNamespace}
          error={nameUnavailable}
          helperText={nameUnavailable ? "Identity is unavailable" : undefined}
        >
          {Object.keys(shinkaiNamespaces).map((namespaceString) => (
            <MenuItem key={namespaceString} value={namespaceString}>
              {namespaceString}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <TextField
        color="secondary"
        label="KAI tokens stake amount"
        onChange={(e) => handleStakeInputChanged(e.target.value)}
        value={stake}
        error={stakeError || balanceError}
        helperText={
          balanceError
            ? "Stake amount exceeds your KAI balance"
            : `Minimum stake requirement for this identity: ${formatEther(
                stakeReq,
              )}`
        }
        sx={{ width: "100%" }}
      />
      {(shinTokenAllowance ?? 0) < stakeBN ? (
        <ApproveKaiButton
          amount={stakeBN}
          callbackOnSuccess={() => {
            queryClient.invalidateQueries({
              queryKey: shinTokenAllowanceQueryKey,
            });
            refetchClaimIdentity();
          }}
          disabled={errorsInInputs}
        />
      ) : (
        <ClaimIdentityButton
          name={identityName}
          namespace={shinkaiNamespaces[identityNamespace]}
          namespaceString={identityNamespace}
          stakeAmount={stakeBN}
          owner={address!}
          disabled={buttonDisabled}
          referrer={
            !!debouncedReferrer ? debouncedReferrer + referrerNamespace : ""
          }
          loading={
            identityName.length > 0 &&
            !errorsInInputs &&
            !claimIdentitySettled &&
            !claimIdentityError
          }
          encryptionPublicKey={encryptionPublicKey.trim()}
          signaturePublicKey={signaturePublicKey.trim()}
          nodeAddress={nodeAddress.trim()}
        />
      )}
      <Accordion
        expanded={advancedOptionsExpanded}
        onChange={(_event, expanded) => {
          setAdvancedOptionsExpanded(expanded);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="advanced-content"
          id="advanced-header"
          sx={{ p: 0 }}
        >
          <Typography sx={{ fontWeight: "600" }}>Advanced options</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Stack sx={{ gap: 2 }}>
            <Stack sx={{ flexDirection: "row", width: "100%" }}>
              {/* "Temporarily" disabled referrer input */}
              {/* <TextField
                color="secondary"
                label="Referrer identity name"
                onChange={(e) => handleReferrerNameInputChanged(e.target.value)}
                value={referrerName}
                error={referrerInvalid}
                helperText={
                  referrerInvalid ? "Identity does not exist" : undefined
                }
                sx={{ flexGrow: 1 }}
              />
              <TextField
                select
                disabled={Object.keys(shinkaiNamespaces).length <= 1}
                color="secondary"
                label="Namespace"
                onChange={(e) =>
                  handleReferrerNamespaceInputChanged(e.target.value)
                }
                value={referrerNamespace}
                error={referrerInvalid}
                helperText={
                  referrerInvalid ? "Identity does not exist" : undefined
                }
              >
                {Object.keys(shinkaiNamespaces).map((namespaceString) => (
                  <MenuItem key={namespaceString} value={namespaceString}>
                    {namespaceString}
                  </MenuItem>
                ))}
              </TextField> */}
            </Stack>
            <TextField
              color="secondary"
              label="Node address"
              onChange={(e) => handleNodeAddressInputChanged(e.target.value)}
              value={nodeAddress}
            />
            <Stack sx={{ gap: 2 }}>
              <Typography sx={{ px: 2, pb: 2 }}>
                You can supply your own public keys instead of having them
                generated
              </Typography>
              <TextField
                color="secondary"
                label="Encryption public key"
                onChange={(e) =>
                  handleEncryptionPublicKeyInputChanged(e.target.value)
                }
                value={encryptionPublicKey}
              />
              <TextField
                color="secondary"
                label="Signature public key"
                onChange={(e) =>
                  handleSignaturePublicKeyInputChanged(e.target.value)
                }
                value={signaturePublicKey}
              />
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
