import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  ListItemText,
  Container,
  IconButton,
  List,
  ListItem,
  Stack,
  Paper,
  Typography,
  Box,
  ListItemIcon,
  Button,
  Dialog,
  DialogContent,
} from "@mui/material";
import ClaimIdentityForm from "../components/ClaimIdentityForm";
import IsConnectedWrapper from "../components/IsConnectedWrapper";
import { Link } from "react-router-dom";
import { useState } from "react";

export default function HomePage() {
  const [isHowToOpen, setIsHowToOpen] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const encryptionPublicKeyFromUrl = queryParameters.get("encryption_pk") ?? "";
  const signaturePublicKeyFromUrl = queryParameters.get("signature_pk") ?? "";
  return (
    <>
      <Container>
        <IsConnectedWrapper>
          <Stack sx={{ alignItems: "center", my: 4 }}>
            <Typography
              variant="h2"
              component="h1"
              sx={{ mb: 4, textAlign: "center" }}
            >
              Register A New Identity
            </Typography>
            {(!encryptionPublicKeyFromUrl || !signaturePublicKeyFromUrl) && (
              <>
                <Button
                  onClick={() => setIsHowToOpen(true)}
                  variant="outlined"
                  sx={{ mb: 5, gap: 1 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                    <path d="M12 9v4" />
                    <path d="M12 17h.01" />
                  </svg>
                  Register Your Identity with Shinkai Desktop App
                </Button>
                <Dialog
                  open={isHowToOpen}
                  onClose={() => setIsHowToOpen(false)}
                  maxWidth="sm"
                  fullWidth
                >
                  <DialogContent
                    sx={{
                      position: "relative",
                      p: 0,
                      width: "100%",
                    }}
                  >
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 1,
                        right: 1,
                        zIndex: 1,
                      }}
                      onClick={() => setIsHowToOpen(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M18 6 6 18" />
                        <path d="m6 6 12 12" />
                      </svg>
                    </IconButton>
                    <HowToRegisterIdentity />
                  </DialogContent>
                </Dialog>
              </>
            )}
            <ClaimIdentityForm />
          </Stack>
        </IsConnectedWrapper>
      </Container>
    </>
  );
}

function HowToRegisterIdentity() {
  return (
    <Card
      sx={{
        maxWidth: "100%",
        width: "100%",
        boxShadow: 0,
        border: "1px solid #E0E0E0",
      }}
    >
      <CardHeader
        sx={{
          position: "relative",
          py: 1.5,
          color: "black",
        }}
        title={
          <Typography variant="subtitle1" sx={{ mb: 0, fontWeight: 600 }}>
            Before Registering Your Identity
          </Typography>
        }
        subheader={
          <Typography variant="subtitle2" sx={{ color: "black" }}>
            Follow these steps to ensure you have the correct keys to register
          </Typography>
        }
      />
      <CardContent
        sx={{
          p: 3,
          pt: 1.5,
          display: "grid",
          gridTemplateColumns: "1fr",
        }}
      >
        <List>
          <ListItem disableGutters sx={{ gap: 1, alignItems: "start" }}>
            <ListItemIcon
              sx={{
                minWidth: 30,
                marginTop: 1,
                width: 30,
                height: 30,
                display: "flex",
              }}
            >
              <Avatar
                sx={{
                  bgcolor: "primary.main",
                  color: "white",
                  borderRadius: 100,
                  width: "100%",
                  height: "100%",
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                1
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="subtitle1" sx={{ mb: 0, fontWeight: 600 }}>
                  Download Shinkai Desktop
                </Typography>
              }
              secondary={
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{ mb: 0, fontWeight: 400 }}
                    color="text.secondary"
                  >
                    Visit our website and download the Shinkai Desktop App.
                  </Typography>

                  <a
                    href="https://www.shinkai.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "black",
                    }}
                  >
                    Download Shinkai Desktop
                  </a>
                </Box>
              }
            />
          </ListItem>

          <ListItem disableGutters sx={{ gap: 1, alignItems: "start" }}>
            <ListItemIcon
              sx={{
                minWidth: 30,
                marginTop: 1,
                width: 30,
                height: 30,
                display: "flex",
              }}
            >
              <Avatar
                sx={{
                  bgcolor: "primary.main",
                  color: "white",
                  borderRadius: 100,
                  width: "100%",
                  height: "100%",
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                2
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="subtitle1" sx={{ mb: 0, fontWeight: 600 }}>
                  Get Your Keys from Shinkai Desktop
                </Typography>
              }
              secondary={
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{ mb: 0, fontWeight: 400 }}
                >
                  Once Shinkai Desktop is installed, go to Settings - General,
                  and click on the "Register Your Shinkai Identity". All
                  configurations/options will be set up for you.
                </Typography>
              }
            />
          </ListItem>
          <ListItem disableGutters sx={{ gap: 1, alignItems: "start" }}>
            <ListItemIcon
              sx={{
                minWidth: 30,
                marginTop: 1,
                width: 30,
                height: 30,
                display: "flex",
              }}
            >
              <Avatar
                sx={{
                  bgcolor: "primary.main",
                  color: "white",
                  borderRadius: 100,
                  width: "100%",
                  height: "100%",
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                3
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="subtitle1" sx={{ mb: 0, fontWeight: 600 }}>
                  Fill out the registration identity form
                </Typography>
              }
              secondary={
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{ mb: 0, fontWeight: 400 }}
                >
                  Enter your shinkai identity name and click "Register
                  Identity". To learn more, you can{" "}
                  <Link
                    to="https://docs.shinkai.com/advanced/shinkai-identity"
                    target="_blank"
                    style={{ color: "black" }}
                  >
                    follow the docs here
                  </Link>{" "}
                  to register an identity.
                </Typography>
              }
            />
          </ListItem>
        </List>

        <Paper
          sx={{
            mt: 2,
            bgcolor: "primary.50",
            borderRadius: 1,
            boxShadow: 0,
            padding: 0,
          }}
        >
          <Box
            sx={{
              width: "100%",
              position: "relative",
              aspectRatio: "16/10",
            }}
          >
            <video
              src="/demo-identity.mp4"
              autoPlay
              muted
              loop
              controls
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        </Paper>
      </CardContent>
    </Card>
  );
}
